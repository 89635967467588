import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#75163F',
        secondary: '#817F26',
        accent: '#004958',
        error: '#b71c1c',
      },
    },
  },
});
